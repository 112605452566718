<template>
  <v-container fluid>
    <section-header
      :title="$t('settingsPage.billing')"
      :has-action="false"
    >
      <template v-slot:additional-actions>
        <div class="mt-4 d-flex">
          <v-chip
            label
            class="px-4"
            :class="{ 'blue--text': activeTab === 'subscription' }"
            :color="activeTab === 'subscription' ? 'blue-light' : 'gray-light'"
            @click="activeTab = 'subscription'"
          >
            {{ $t('billing.subscription') }}
          </v-chip>

          <v-chip
            label
            class="ml-2 px-4"
            :class="{ 'blue--text': activeTab === 'history' }"
            :color="activeTab === 'history' ? 'blue-light' : 'gray-light'"
            @click="activeTab = 'history'"
          >
            {{ $t('billing.billingHistory') }}
          </v-chip>
        </div>
      </template>
    </section-header>

    <v-card class="py-6 px-6 mt-3 d-flex justify-center" rounded="lg" elevation="0" width="100%">
      <subscription v-if="activeTab === 'subscription'"  />
      <history v-else />
    </v-card>
  </v-container>
</template>

<script>
import SectionHeader from '@/components/Form/SectionHeader.vue';
import Subscription from '@/components/Settings/Billing/Subscription.vue';
import History from '@/components/Settings/Billing/History.vue';

export default {
  name: 'Billing',

  components: {
    SectionHeader,
    Subscription,
    History,
  },

  data () {
    return {
      activeTab: 'subscription',
    }
  },
}
</script>